@import (once) 'var.less';
@import (once) 'palette.less';
/* Override variables defined in main theme */

@menu-highlight-color: @kruze-menu-highlight-color;
@menu-item-active-bg: @kruze-geek-blue-2;
/*
 Override default theme classes here with {
   classname: {}, ...
 },
*/
.App {
  /*.ant-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }*/

  .ant-card-meta-title {
    margin-bottom: 0 !important;
  }

  th.ant-table-cell {
    font-weight: bold;
    &:before {
      content: none !important;
    }
  }

  .ant-tabs {
    .ant-tabs-nav-list {
      padding-top: 4px;
      .ant-tabs-tab {
        font-size: @font-size-base;
        justify-content: center;
      }
    }
  }

  //Breadcrumbs
  .ant-breadcrumb {
    a, .ant-breadcrumb-separator {
      color: @blue-base
    }
    >span:last-child {
      color: @text-color-secondary !important;
    };
  }
}
