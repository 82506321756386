/**
  Create common less styles here which will be available in whole system
 */

@import (once) "var";
@import (once) "palette";

.note {
  font-size: @note-font-size;
  display: block;
}

.fullWidth {
  width: 100%;
}

.centerContent {
  text-align: center;
}

.dynamicBtnHeight {
  white-space: normal;
  height: auto
}

.notificationError {
  background-color: @kruze-error;
  color: @kruze-error;
  :global {
    .ant-notification-notice-message {
      color: @kruze-error;
    }
  }
}

.circlePictureCardField {
  img, embed {
    border-radius: 50% !important;
    background-color: transparent !important;
    z-index: -1;
  }
  :global {
    .ant-upload-select-picture-card,
    .ant-upload-list-item-list-type-picture-card,
    .ant-upload-list-item-info {
      border-radius: 50% !important;
      background-color: transparent !important;
    }
  }
}

.disabledLink {
  pointer-events: none !important;
  cursor: none;
  color: @black;
  opacity: 0.2;
}

:global {
  #sentry-feedback {
    --left: 1px;
    --right: unset;
  }
}


