@import (once) '../../theme/var';
@import (once) '../../theme/palette';

.lineDivider {
  margin: 0;
}

.success {
  color: @kruze-success !important;
  border-top-color: @kruze-success !important;
}
