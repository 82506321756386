/* @import (once) '~antd/lib/style/themes/default.less';*/
/* @import (once) '~antd/dist/antd.less';*/
/**
  Define custom variables for font-size, padding and etc...
  which will be used in project less files (features and etc...)
*/
/*
  Define Application colors and styles here
  Usage @import (reference) "core/theme/palette";
*/
/* Override variables defined in main theme */
/*
 Override default theme classes here with {
   classname: {}, ...
 },
*/
.App {
  /*.ant-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }*/
}
.App .ant-card-meta-title {
  margin-bottom: 0 !important;
}
.App th.ant-table-cell {
  font-weight: bold;
}
.App th.ant-table-cell:before {
  content: none !important;
}
.App .ant-tabs .ant-tabs-nav-list {
  padding-top: 4px;
}
.App .ant-tabs .ant-tabs-nav-list .ant-tabs-tab {
  font-size: 12px;
  justify-content: center;
}
.App .ant-breadcrumb a,
.App .ant-breadcrumb .ant-breadcrumb-separator {
  color: #1677ff;
}
.App .ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.45) !important;
}
