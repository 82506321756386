@import (once) "../../../theme/var";
@import (once) "../../../theme/palette";

.successButton {
  background-color: @kruze-success !important;
  border-color: @kruze-success !important;
  color: white !important;
  &:hover {
    background-color: @success-color-hover !important;
    border-color: @success-color-hover !important;
    color: white !important;
  }
}

.successGhostButton {
  background-color: white !important;
  border-color: @kruze-success !important;
  color: @kruze-success !important;
  &:hover {
    border-color: @success-color-hover !important;
    background-color: white !important;
    color: @success-color-hover !important;
  }
}
