@import (once) '../../../theme/var';
@import (once) '../../../theme/palette';

.cardCarousel {
  .content {
    background: #5f9ea0;
    color: #fff;
    max-height: 15vw;
    margin: 10px;
    position: relative;
    text-align: center;
  }

  :global {
    .slick-prev, .slick-next {
      &:before {
        color: black;
      }
    }
  }
}



