@import (once) '../../../theme/var.less';
@import (once) '../../../theme/palette.less';

.editor {
  border: 1px solid @normal-color;
  padding: 4px 11px;
  min-height: 100px;
  max-height: 150px;
  overflow-y: auto;
  cursor: text;
  &:focus-within, &:hover {
    border-color: @primary-5 !important;
  }
}

.toolbar {
  padding: 0;
  margin-bottom: 0;
  border: none;
  display: flex;
  .rdw-option-wrapper {
    margin: 0;
  }

  :global {
    .rdw-fontsize-dropdown {
      a {
        color: @black !important;
      }
    }
  }
}

.linkDropdown {
  background: red;
}

.linkPopup {
  height: auto;
}
