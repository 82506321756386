@import (once) '../../theme/var.less';
@import (once) '../../theme/palette.less';

.noteAlert {
  display: inline-flex !important;
  line-height: 12px !important;
}

.successColor {
  :global {
    .ant-alert-message {
      color: @kruze-success;
    }
  }
}

.infoColor {
  :global {
    .ant-alert-message {
      color: @blue-base;
    }
    border: 1px solid @kruze-geek-blue;
  }
}

.warningColor {
  :global {
    .ant-alert-message {
      color: @kruze-warning;
    }
  }
}

.errorColor {
  :global {
    .ant-alert-message {
      color: @kruze-error;
    }
  }
}
.defaultColor {
  :global {
    .ant-alert-message {
      color: @black;
    }
    border: 1px solid @normal-color;
    background-color: @white;
  }
}
