@import (once) '../../theme/palette.less';

.legend {
  color: @kruze-black-3 !important;
}

.legendDot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
