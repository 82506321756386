@import (once) '../../theme/palette';

.pageTitle {
  margin-bottom: 0 !important;
}
.pageHeader {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.clientHeader {
  background: @white;
  padding: 15px;
  border-radius: 4px;
}
