@import (once) '../../theme/var.less';
@import (once) '../../theme/palette.less';

.dottedText {
  .dot {
    border-radius: 100%;
    display: inline-flex;
    vertical-align: middle;
  }

  .successBg {
    background-color: @kruze-success;
  }

  .infoBg {
    background-color: @blue-base;
  }

  .warningBg {
    background-color: @kruze-warning;
  }

  .errorBg {
    background-color: @kruze-error;
  }

  .successColor {
    color: @kruze-success;
  }

  .infoColor {
    color: @blue-base;
  }

  .warningColor {
    color: @kruze-warning;
  }

  .errorColor {
    color: @kruze-error;
  }
}
