@import (once) '../../theme/var.less';
@import (once) '../../theme/palette.less';

.inputError {
  border-color: @kruze-error !important;
  &:hover {
    border-color:@kruze-error !important;
  }
  :global {
    .ant-input {
      border-color: @kruze-error !important;
    }
  }
}

.draftTextError {
  border-color: @kruze-error !important;
  &:hover {
    border-color: @kruze-error !important;
  }
}

.radioError {
  border-color: @kruze-error !important;
  &:before {
    background-color: transparent !important;
  }
  &:after {
    background-color: transparent !important;
  }
}

.selectError {
  :global {
    .ant-select-selector {
      border-color: @kruze-error !important;
    }
  }
}

.uploadError {
  border: 1px solid @kruze-error !important;
}

.switchPadding {
  margin-right: 0.5rem;
}

.numberStyle {
  width: 100% !important;
}

.datePickerStyle {
  width: 100%;
}

.datePickerError {
  border-color: @kruze-error !important;
  &:hover {
    border-color: @kruze-error !important;
  }
}

.dragFieldContent {
  background-color: transparent !important;
  :global {
    .ant-upload-drag-container {
      z-index: -1;
    }
  }
}
