@import (once) '../../theme/var';
@import (once) '../../theme/palette';

.history {
  display: flex;
  flex-flow: column;
  max-height: 580px;

  .historyList {
    flex-grow: 1;
    overflow-y: auto;
  }

  .comment {
    .commentAuthor {
      font-size: 14px;
    }
    :global {
      .ant-comment-content-author {
        justify-content: space-between;
      }
      .ant-comment-inner {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}

.dividerText {
  font-size: 14px;
}

.historyEndDivider {
  font-size: 14px;
  //align-items: flex-start;
}
