.drawer {
  :global {
    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }
  }
  .drawerButtons {
    display: flex;
    justify-content: flex-end;
  }

  .drawerTitle {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}
